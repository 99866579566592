import consumer from "./consumer"

function remove_waiting(upgrade_type) {
    $("[data-upgrade-type='"+upgrade_type+"']").removeClass("bc_waiting")
    if(upgrade_type === "claim")
        update_ux_from_server()
}

consumer.subscriptions.create({channel: "UserChannel", user_id: user_id, ws_token: ws_token}, {
    connected() {
    },

    disconnected() {
    },

    received(data) {
        if (typeof get_cashback === 'function')
            get_cashback()
        if (data.ws_type === "new_rank_data" && window.location.pathname.includes(data.url)) {
            toastr_wrapper({
                "toast_type": "success",
                "title": "There is new Data available. ",
                "text": "Click here to reload Now",
                "click": function () {
                    if (data.modern) {
                        $(".format_switch.modern").removeClass("btm-secondary")
                        $(".format_switch.wild").addClass("btm-secondary")
                    } else {
                        $(".format_switch.wild").removeClass("btm-secondary")
                        $(".format_switch.modern").addClass("btm-secondary")
                    }
                    render_chart()
                    render_card_stats()
                }
            })
        } else if (data.ws_type === "alert") {
            toastr_wrapper(data.data)
            get_notifications()
            if (data.page === "prime_manage" && window.location.pathname === "/prime/manage") {
                refresh_prime()
            } else if (data.page === "prime" && window.location.pathname === "/prime") {
                $(".loading-prime, .loading-omega, .loading-trial").addClass("active")
                reload_after_buy()
                render_balances2()
            }

        } else if (data.ws_type === "go_claim") {
            if (window.location.host.includes("go.")) {
                go_stats = data.stats
                update_go()
            }
        } else if (data.ws_type === "go_refresh_data") {
            if (window.location.host.includes("go.")) {
                update_go()
            }
        } else if (data.ws_type === "go_log") {
            if (window.location.host.includes("go.")) {
                add_go_log(data.html, data.id)
                if(data.trx_id !== undefined)
                    unwait(data.trx_id)
                if(data.activity_log_type !== undefined && data.activity_log_type === "activated")
                    window.location.reload()
                update_ux_from_server()
            }
        } else if (data.ws_type === "go_notification") {
            if (window.location.host.includes("go.")) {
                toastr_wrapper({
                    "toast_type": data.toast_type,
                    "text": data.text
                })

                if(data.upgrade_type !== undefined)
                    remove_waiting(data.upgrade_type)
            }
        }
    }
});


consumer.subscriptions.create({channel: "UserChannel", user_id: 0, token: ""}, {
    connected() {

    },

    disconnected() {
    },

    received(data) {
        if (data.type === "proposal_update" && window.location.pathname.includes("/proposals") && !window.location.pathname.includes("/gls")) {
            get_proposal(data.id)

        }
        if (data.ws_type === "go_refresh_data") {
            if (window.location.host.includes("go.")) {
                update_go()
            }
        }
        if (data.type === "proposal_new") {

            toastr_wrapper({
                "toast_type": "success",
                title: "New Pre-Proposal: " + data.title,
                text: "Theres a new Pre-Proposal available for voting, click here to open it in a new window!",
                timeOut: 0,
                click: function () {
                    let win = window.open(data.url);
                    if (win) {
                        win.focus();
                    } else {
                        toastr_wrapper({
                            "toast_type": "warning",
                            "text": "Please allow popups for this website. Or click me to visit it in this Tab",
                            click: function () {
                                Turbolinks.visit(data.url)
                            }
                        })
                    }
                }
            })

        } else if (data.type === "real_proposal_new") {

            toastr_wrapper({
                "toast_type": "success",
                title: "New SL Proposal: " + data.title,
                text: "Theres a new Proposal available on the official Splinterlands Page, click here to open it in a new window!",
                timeOut: 0,
                click: function () {
                    let win = window.open(data.url);
                    if (win) {
                        win.focus();
                    } else {
                        toastr_wrapper({
                            "toast_type": "warning",
                            "text": "Please allow popups for this website. Or click me to visit it in this Tab",
                            click: function () {
                                Turbolinks.visit(data.url)
                            }
                        })
                    }
                }
            })

        }
        if (data.type === "restarted") {
            alert("restarted")
        }
    }
});

