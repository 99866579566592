// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks")
require("@rails/activestorage").start()
require("channels")
// require("@toast-ui/editor")


// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import swal from 'sweetalert';
import Editor from '@toast-ui/editor';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { scrollTo, scrollIntoView } from 'scroll-js';

Turbolinks.start()

window.jQuery = $;
window.$ = $;


(function ($) {
    $.fn.inputFilter = function (inputFilter) {
        return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            } else {
                this.value = "";
            }
        });
    };
}(jQuery))

require('pagination.js')
require('sticky')
require('lazy.js')
require('chart')
require("chosen-js")

import 'bootstrap'
import 'popper.js'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css'


window._scrollTo = function(selector, yOffset = 0, container) {
    if(container === undefined)
        container = window
    let auto_scroll_sub_tmp = auto_scroll_sub

    const el = document.querySelector(selector);
    let y = 0
    if(container === window) {
        let y2 = container.pageYOffset
        if (y2 === undefined)
            y2 = 0
        y = el.getBoundingClientRect().top + y2 + yOffset;
    }else{
        y = $(el).position().top + yOffset
    }


    scrollTo(container, { top: y }).then(function () {
        $(container).scrollTop(y)
    });

    auto_scroll_sub = auto_scroll_sub_tmp
}
window.create_editor = function(content, selector){

    let el = document.querySelector(selector)
    if (el !== null) {
        let editor = new Editor({
            el: el,
            height: '500px',
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            usageStatistics: false,
            theme: "dark",
            width: "100%",
            initialValue: content
        })
        return editor
    }

}
window.create_viewer = function(content, selector){
    let el = document.querySelector(selector)
    if (el !== null) {
        let viewer = new Viewer({
            el: el,
            height: '600px',
            theme: "dark",
            initialValue: content
        });
    }
}
let sd = require('showdown')
let converter = new sd.Converter();

window.parse_markdown = function(content){
    return converter.makeHtml(content);
}
window.parse_html_to_markdown = function(content){
    return converter.makeMarkdown(content);
}
$(document).ready(function () {


})